import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  connect() {
    this.lastStatus = this.data.get("status")
    this._connectCable()
  }

  disconnect() {
    this._disconnectCable()
  }

  _connectCable() {
    if (!this.importStatusChannel) {
      this.importStatusChannel = consumer.subscriptions.create({
        channel: "ImportStatusChannel",
        id: this.data.get("id")
      }, {
        connected: () => {
          this.importStatusChannel.perform("update_status", {
            id: this.data.get("id")
          })
        },
        received: data => {
          if (data["status"] !== this.lastStatus) {
            this.lastStatus = data["status"]
            if (this.data.get("refresh")) {
              this._refreshPage()
            } else if (this.data.get("update")) {
              this.element.innerHTML = data["index_html"]
            }
          }
        }
      });
    }
  }

  _disconnectCable() {
    if (this.importStatusChannel) {
      this.importStatusChannel.unsubscribe()
      delete this.importStatusChannel
    }
  }

  _refreshPage() {
    Turbolinks.visit()
  }
}
