import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "field"]

  submit() {
    this.fieldTargets.forEach(target => { target.classList.add("disabled") })
    Rails.fire(this.formTarget, "submit")
  }
}
