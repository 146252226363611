import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  connect() {
    this._connectCable()
  }

  disconnect() {
    this._disconnectCable()
  }

  _connectCable() {
    if (!this.trainingLogChannel) {
      this.trainingLogChannel = consumer.subscriptions.create({
        channel: "TrainingLogChannel",
        id: this.data.get("id")
      }, {
        connected: () => {
          this.trainingLogChannel.perform("update_log", {
            id: this.data.get("id")
          })
        },
        received: data => {
          this._updateTrainingLog(data["log_html"])
        }
      });
    }
  }

  _disconnectCable() {
    if (this.trainingLogChannel) {
      this.trainingLogChannel.unsubscribe()
      delete this.trainingLogChannel
    }
  }

  _updateTrainingLog(log_html) {
    this.element.innerHTML = log_html
  }
}
