import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "btnShow", "btnHide"]

  connect() {
    this.btnHiddenClass = "hidden"
    this.fieldShownType = "text"
    this.fieldHiddenType = "password"

    this._toggleVisibility(false)
  }

  show(event) {
    event.preventDefault()
    this._toggleVisibility(true)
  }

  hide(event) {
    event.preventDefault()
    this._toggleVisibility(false)
  }

  _toggleVisibility(visible) {
    if (visible) {
      this.fieldTarget.type = this.fieldShownType;
      this.btnShowTarget.classList.add(this.btnHiddenClass)
      this.btnHideTarget.classList.remove(this.btnHiddenClass)
    } else {
      this.fieldTarget.type = this.fieldHiddenType;
      this.btnShowTarget.classList.remove(this.btnHiddenClass)
      this.btnHideTarget.classList.add(this.btnHiddenClass)
    }
  }
}
