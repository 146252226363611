import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selector", "products", "images", "categories",
    "labels", "labelOptions", "categoryLabels", "productLabels"]

  connect() {
    this.updateHintsVisibility()
  }

  updateHintsVisibility() {
    const selected = this.selectorTarget.value

    this._toggleVisibility(this.productsTarget,       selected === "products")
    this._toggleVisibility(this.imagesTarget,         selected === "images")
    this._toggleVisibility(this.categoriesTarget,     selected === "categories")
    this._toggleVisibility(this.labelsTarget,         selected === "labels")
    this._toggleVisibility(this.labelOptionsTarget,   selected === "label_options")
    this._toggleVisibility(this.categoryLabelsTarget, selected === "category_labels")
    this._toggleVisibility(this.productLabelsTarget,  selected === "product_labels")
  }

  _toggleVisibility(target, visible) {
    target.classList.toggle("hidden", !visible)
  }
}
