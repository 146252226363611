import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source"]

  copy(event) {
    event.preventDefault()

    if (this._isPasswordField()) {
      this._togglePasswordVisibility(true)
      this._copyField()
      this._togglePasswordVisibility(false)
    } else {
      this._copyField()
    }
  }

  _isPasswordField() {
    return this.sourceTarget.type === "password"
  }

  _togglePasswordVisibility(visibile) {
    this.sourceTarget.type = (visibile ? "text" : "password")
  }

  _copyField() {
    this.sourceTarget.focus()
    this.sourceTarget.select()
    document.execCommand("copy")
  }
}
