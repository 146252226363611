import { Controller } from "stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["toggler", "menu"]

  connect() {
    this.activeClass = "active"
    this.popper = null
  }

  disconnect() {
    this._forceHide()
  }

  toggle(event) {
    event.preventDefault()

    this._destroyPopper()

    if (this.menuTarget.classList.toggle(this.activeClass)) {
      this.popper = createPopper(this.togglerTarget, this.menuTarget, {
        placement: this.data.get("placement"),
      })
    }
  }

  hide(event) {
    if (this.element.contains(event.target) === false) {
      this._forceHide()
    }
  }

  _forceHide() {
    this._destroyPopper()
    this.menuTarget.classList.remove(this.activeClass)
  }

  _destroyPopper() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  }
}
